import { motion } from 'framer-motion'
import React, {forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef, useState} from 'react'
import { Strings } from '../../../Strings/nl'
import {useTranslation} from "react-i18next";

export interface FilePickerProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,'onChange'> {
    label: string,
    sublabel?: string,
    initial?: string,
    id?: string,
    required?: boolean,
    accept?: Array<string>,
    onChange?: (file: File) => void,
    onChangeBlob?: (blob: string) => void
}

export type FilePickerHandle = {
    clearInput: () => void
}

const FilePicker: ForwardRefRenderFunction<FilePickerHandle, FilePickerProps> = (props: FilePickerProps, ref?) => {

    const { label, sublabel, onChange, onChangeBlob, required, accept, initial, id, ...restProps } = props

    const labelOrId = id || label
    const { t } = useTranslation()
    const [ value, setValue ] = useState(initial || '')

    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => ({
        clearInput() {
            setValue('')
            if (inputRef.current) {
                inputRef.current.value = ''
            }
        }
    }))

    const onFileSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
        
        let file = evt.currentTarget.files?.item(0)
        
        if(!file) return
        
        setValue(file.name)
        if(onChange) onChange(file)
        
        let blob = URL.createObjectURL(file)
        
        if(onChangeBlob) onChangeBlob(blob)
    }

    return (
        <div {...restProps} className='flex flex-col mb-6'>
            { <motion.div animate={ (value.length > 0 || !required) ? { height: '0rem' } : { height: '1.2rem' } } className='text-sm overflow-hidden text-red-400 mb-2'>{ (value.length <= 0 && required && t('required')) || '\u00a0' }</motion.div> }
            <label>{ label }{ required && <span className='text-red-400'>*</span> } { sublabel && <span className='opacity-70 text-sm'>({ sublabel })</span> }</label>
            <label htmlFor={ labelOrId } className='flex mt-2 border-2 border-black border-opacity-10 p-1 cursor-pointer'>
                <div className='flex cursor-pointer bg-accent transition-colors hover:bg-accent-light px-6 py-2 text-white justify-center gap-4 items-center'>{t('organisation:theme:file')}</div>
                <div className='flex items-center ml-4'>{ value || t('noFile') }</div>
            </label>
            
            <input ref={inputRef} hidden onChange={ onFileSelect } type='file' accept={ (accept || []).join(',') } name={ labelOrId } id={ labelOrId } />
            <input name={ '@VALID@' + labelOrId } type="hidden" value={ (value.length > 0) + '' } />
        </div>
    )
}

export default forwardRef(FilePicker)